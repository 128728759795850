//@use '@ef-global/web-ui/scss/typography/variables/font-weights';
@use '@ef-global/web-ui/scss/settings/variables/color';
@use '@ef-global/web-ui/scss/settings/variables/spacing';

.ef-table-w {
  overflow-y: auto;
}

.ef-table {
  table-layout: fixed;
  min-width: 100%;
  font-size: 12px;
  text-align: left;
  border-collapse: collapse;

  &.-text-medium {
    font-size: 14px;
  }

  &.-text-large {
    font-size: 16px;
  }

  &.-layout-auto {
    table-layout: auto;
  }

  &.-align-cells-top tr {
    vertical-align: top;
  }

  th,
  td {
    padding: spacing.pad(xs);
    color: var(--color-text, color.$color-text);
    white-space: normal;
    font-weight: normal;
    border-bottom: 1px solid var(--color-ui-border);
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  td:first-child,
  th:first-child {
    padding-left: 0;
  }

  th {
    border-bottom: 2px solid var(--color-ui-border);
    font-weight: var(--fw-bold, 700);
  }

  tbody td:first-child,
  thead th:first-child {
    font-weight: var(--fw-bold, 700);
  }

  tfoot tr td {
    font-size: 11px;
  }
}

.bordered {
  th,
  td {
    border: 1px solid var(--color-ui-border);
  }

  td:last-child,
  th:last-child {
    padding-right: spacing.pad(xs);
  }

  td:first-child,
  th:first-child {
    padding-left: spacing.pad(xs);
  }
}

.striped {
  tbody tr:nth-child(odd) {
    background-color: var(
                    --color-striped-table-highlight,
                    #{rgba(color.$color-text, 0.03)}
    );
  }

  td:last-child,
  th:last-child {
    padding-right: spacing.pad(xs);
  }

  td:first-child,
  th:first-child {
    padding-left: spacing.pad(xs);
  }

  .-inv &,
  &.-light {
    tbody tr:nth-child(odd) {
      background-color: var(
                      --color-striped-table-highlight-inv,
                      #{rgba(color.$color-white, 0.05)}
      );
    }
  }
}

.filled {
  background-color: var(--color-filled-table-bg, #{rgba(color.$color-text, 0.03)});

  .-inv &,
  &.-light {
    background-color: var(
                    --color-filled-table-bg-inv,
                    #{rgba(color.$color-white, 0.05)}
    );
  }
}

.unset-header-styles th {
  border-bottom: 2px solid var(--color-ui-border);
  font-weight: normal;
}

.unset-col-header-styles tbody td:first-child,
.unset-col-header-styles thead th:first-child {
  font-weight: normal;
}