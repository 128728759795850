.storyCardDate {
  font-size: 12px;
  margin-bottom: 8px;
  display: block;
}

.title {
  max-width: 282px;
  border-bottom: 0 !important;
  font-size: 16px;
  p {
    white-space: normal;
    overflow: visible;
  }
  strong {
    display: block;
  }
}

.storyImage {
  border-radius: 8px;
}