.image {
  width: 100%;
  object-fit: cover;
  position: relative;
  opacity: 0.1;
  transition: opacity 0.5s ease;
  overflow: hidden;

  &.loaded {
    opacity: 1;
  }
}

.imageFigure {
  width: 100%;
  overflow: hidden;

  &.radius_s {
    border-radius: 8px;
  }

  &.radius_m {
    border-radius: 16px;
  }

  &.fitHeight {
    height: fit-content;
  }
}
