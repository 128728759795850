@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.eliteForm {
  flex: 1;
}

.consultationSection {
  gap: 20px;
  margin-top: 40px;
}

.personalDetails {
  display: flex;
}

.consultationWrapper {
  flex-direction: column;
}

.consultationDetails {
  flex: 0 1 50%;
  flex-wrap: wrap;
  gap: 20px;

  legend {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--color-text);
  }
  div {
    margin-top: 16px;
  }
}

.consultationRadioGroup {
  display: flex;
  flex-wrap: wrap;

  label {
    cursor: pointer;
    margin-top: 16px;
  }

  @include breakpoints.breakpoint(s) {
    label {
      margin-left: 24px;
    }
    margin-left: -24px;
  }
}

.scheduleRadioInput {
  --boolean-bg: white;
  --boolean-check-color: #cf01a6;
  border: 1px solid var(--input-border);

  &:checked {
    --boolean-bg: #cf01a6;
    --boolean-check-color: white;
    border: transparent;
  }

  &:before {
    background-color: white;
  }
}

.consultationCheckboxGroup {
  display: flex;
  flex-wrap: wrap;

  label {
    margin-top: 16px;
    margin-right: 24px;
  }
}

.scheduleCheckboxInput {
  width: 100%;

  &:checked {
    :global(~ .ef-boolean__element.-checkbox:before) {
      background-image: url('/inputs/check--active.svg');
    }

    :global(~ .ef-boolean__element.-checkbox) {
      --color-ui-accent: #cf01a6;
    }
  }
}

.divider {
  margin-top: 64px;
  margin-bottom: 64px;
  height: 1px;
  background-color: #bababa;
  opacity: 100%;
  padding: 0;
}

.efFormFieldset {
  margin-bottom: 64px;
}

.submitButton {
  --btn-bg: var(--color-pink);
}