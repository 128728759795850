.playerInner {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.6, 1), opacity 0.15s cubic-bezier(0.4, 0, 0.6, 1);;

  &:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.3);
  }
}

.overlayHidden {
  opacity: 0;
  pointer-events: none;
}

.playButton {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caption {
  display: flex;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-divider);
}

.captionTitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
}