@use '@ef-global/web-ui/scss/settings/mixins/icons';
@use '@ef-global/web-ui/scss/settings/variables/color';
@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.ef-input-w {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tile {
  position: relative;
}

// Labels
.ef-boolean__label,
.tileLabel {
  color: var(--color-text);
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  display: block;
  margin-right: 32px;
}

.ef-boolean__input-wrapper {
  position: relative;
}

.ef-boolean,
.tileWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  cursor: pointer;
}

.tileBg {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid var(--input-border);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.ef-boolean__input,
.tileInput {
  display: grid;
  place-content: center;
  appearance: none;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.5);
  border: 1px solid var(--input-border);
  position: absolute;
  right: 16px;
  z-index: 1;
  cursor: pointer;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    transform: scale(0);
  }

  &:checked {
    border-color: transparent;
  }

  &:checked:before {
    background-color: var(--boolean-check-color, white);
    transform: scale(1);
  }

  &:checked ~ .tileBg {
    background: var(--color-pink);
    border-color: transparent;
  }

  &:checked ~ .tileLabel {
    color: var(--color-text--light);
  }
}

.ef-boolean {
  padding: 0;

  .ef-boolean__input {
    position: relative;
    right: auto;
    border: none;

    &-primary {
      background: var(--boolean-bg-color, var(--color-text));
      &:checked {
        background-color: var(--color-text);
      }
    }

    &-secondary {
      background: white;
      border: 1px solid var(--input-border);
      &:checked {
        background: var(--color-pink);
        border: none;
      }
    }
  }
}

//.-is-invalid {
//  input, :global(.ef-boolean__element) {
//    border-color: var(
//                    --color-notification-warning,
//                    color.$color-notification-warning
//    );
//  }
//}
//
//.-is-valid {
//  border-color: inherit;
//}

.tile {
  --color-primary: var(--color-pink);
  --checkout-accent: var(--color-pink);
  --color-ui-accent: var(--checkout-accent);
  --input-boolean-tile-bg: white;
  --input-boolean-tile-bg--checked: var(--checkout-accent);
  --input-boolean-tile-box-shadow-enabled: 0 2px 2px 0 rgba(0,0,0,0.2);
  --btn-primary-bg: var(--checkout-accent);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 48px 24px;

  @include breakpoints.breakpoint(m) {
    padding: 48px 64px;
  }

  .tileInput:checked ~ .tileBg {
    border: 1px solid var(--checkout-accent);
    background: var(--color-pink);
  }
}
