.heading {
  width: 100%;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  h1, h2, h3, h4, h5, h6 {
    max-width: none;
    margin-bottom: 0;
  }
}