@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.container {
  position: relative;
  overflow: hidden;
}

.content {
  grid-column: main-start / main-end;
  composes: inner-grid from "../../styles/_layout.scss";

  .itemWrapper {
    flex: 1 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    align-items: center;

    &.active {
      opacity: 1;
    }
  }

  .carousel {
    grid-column: col-3 / col-10;
  }

  :global(.carousel-blockquote) {
    @include breakpoints.breakpoint(l) {
      display: flex;
      flex-direction: column-reverse;

      cite {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }
}

.image {
  grid-column: bounds-start / bounds-end;
  composes: layout-grid from "../../styles/_layout.scss";

  .itemWrapper {
    scroll-snap-align: center;
    box-sizing: content-box;
    display: flex;
    flex: 0 0 calc(100% - 48px);
    width: calc(100% - 48px);

    &:first-child {
      padding-left: calc((50vw - 50%) + 24px);
    }

    &:last-child {
      padding-right: calc((50vw - 50%) + 24px);
    }

    @include breakpoints.breakpoint(l) {
      flex: 0 0 960px;
      width: 960px;

      &:first-child {
        padding-left: calc(50vw - (960px / 2));
      }

      &:last-child {
        padding-right: calc(50vw - (960px / 2));
      }
    }

    @include breakpoints.breakpoint(xl) {
      flex: 0 0 1200px;
      width: 1200px;

      &:first-child {
        padding-left: calc(50vw - (1200px / 2));
      }

      &:last-child {
        padding-right: calc(50vw - (1200px / 2));
      }
    }
  }

  .carousel {
    grid-column: bounds-start / bounds-end;
  }

  .itemWrapper {
    opacity: 0.5;
    transition: opacity 0.5s ease-out;

    &.active {
      opacity: 1;
    }
  }
}

.carousel {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 8px;
  overflow-scrolling: touch;

  @include breakpoints.breakpoint(l) {
    gap: 24px;
  }

  .item {
    flex: 1;
  }
}

// Hide scrollbar in WebKit and Blink powered Browsers.
.carousel::-webkit-scrollbar {
  display: none;
}

.itemWrapper {
  scroll-snap-align: center;
  display: flex;
}

.controlButtonRound {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.5s ease;
  color: currentColor;

  &.inactive {
    pointer-events: none;
    opacity: 0.7;

    :global .ef-icon {
      opacity: 0.5;
    }
  }
}

.contentControls {
  @include breakpoints.breakpoint(m down) {
    grid-column: main;
    grid-row: 2;
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
    gap: 16px;
  }

  @include breakpoints.breakpoint(l) {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    text-align: center;

    .controlButtonPrev {
      position: absolute;
      left: 0;
      top: calc(50% - 24px);
    }

    .controlButtonNext {
      position: absolute;
      right: 0;
      top: calc(50% - 24px);
    }
  }
}

.imageControls {
  grid-column-end: main-end;
}

.controlsWrap {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  grid-column: main-start / main-end;
  grid-row: 2/3;
  margin-top: 16px;
}

.scrollCarouselButtons {
  display: flex;
  gap: 8px;

  .scrollCarouselButton {
    color: var(--color-text);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &.inactive {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.pagination {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;

  .paginationBullet {
    width: 4px;
    display: block;
    height: 4px;
    flex: 0 0 auto;
    border-radius: 2px;
    transition: width 0.2s ease;
    opacity: 1;
    background: var(--color-text);

    &.active {
      width: 18px;
    }
  }
}