@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.storyCardsGrid {
  display: grid;
  grid-gap: 16px;
  grid-column: main-start / main-end;

  @include breakpoints.breakpoint(l) {
    grid-gap: 24px;
  }

  &.show-2 {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }

  &.show-3 {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
  }

  &.show-4 {
    grid-template-columns: repeat(4, minmax(10px, 1fr));
  }

  &.show-6 {
    grid-template-columns: repeat(6, minmax(10px, 1fr));
  }
}

.wrapper {
  grid-column: main-start / main-end;
  grid-auto-rows: min-content;

  .story-cards-children {
    grid-column: main-start / main-end;
  }
}

.showCarousel {
  grid-column: bounds-start / bounds-end;
}

.articleHeading {
  grid-column: main-start / main-end;
  padding-bottom: 14px;
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  border-bottom: 1px solid rgba(25, 25, 25, 0.3);
}