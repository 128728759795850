@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.gironaForm {
  display: grid;
  grid-column: var(--col-start)/var(--col-end);
  justify-content: center;
  text-align: center;
}

.gironaFormInputs {
  display: grid;
  gap: 24px;
  width: 100%;
  grid-column: main-start / main-end;
  grid-template-columns: minmax(10px, 1fr);
  @include breakpoints.breakpoint(l) {
    grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr);
  }
}

.formButton {
  margin-top: 56px;
  --btn-accent: white;
  --btn-bg: var(--color-pink);
  --btn-icon-color: var(--color-pink);
}

.errorMessage {
  color: white;
  margin-top: 8px;
  display: block;
  font-size: 12px;
  position: absolute;
}

.headingWrapper {
  max-width: 650px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 48px;
}

.headingDescription {
  padding-left: 50px;
  padding-right: 50px;
}