.videoCard {
  cursor: pointer;
  strong {
    font-weight: 400;
  }
  div, .index-item__title {
    border: none !important;
    text-align: center;
  }
}

.videoCardWrap {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.videoCardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.6, 1);

  &:hover {
    background-color: rgba(0,0,0,0.3);
  }
}

.videoCardPlay {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}